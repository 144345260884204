import React, { useState } from "react"
import { Link } from "gatsby"
import { ChevronRight } from "react-feather"
import axios from "axios"
import logo from "../images/logo.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Input from "../components/Input/Input"
import CalendlyEmbed from "../components/CalendlyEmbed"

const Mission = () => {
  const [showCalendly, setShowCalendly] = useState(false)
  const [isEmailFocused, setIsEmailFocused] = useState(false)
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState("")
  const [isButtonLoading, setIsButtonLoading] = useState(false)

  

  const onSubmit = e => {
    // Set button state as loading
    setIsButtonLoading(true)

    let currentDate = new Date();
    let finalDate = currentDate.getDate() +'-'+ (currentDate.getMonth() + 1) +'-'+ currentDate.getFullYear() +' '+ currentDate.getHours() +':'+ currentDate.getMinutes() +':'+ currentDate.getSeconds();

    let data = [
      {
        email,
        date: finalDate
      },
    ]

    axios
      .post(
        "https://v1.nocodeapi.com/aide/airtable/qCnnfgIEoqUGxkjJ?tableName=email_form",
        data
      )
      .then(function (response) {
        if (response.data) {
          if (200 === response.status) {
            // Success, show notification and close the modal
            setIsButtonLoading(false)
            setShowCalendly(true)

            /*setTimeout(function () {
              // Reset all of the errors
              setEmail("")
            }, 1000)*/
          }
        }
      })
      .catch(function (error) {
        console.log(error)
      })
      .finally(function () {
        // Make button active again since either call succeeded or failed
        setIsButtonLoading(false)
      })
  }

  return (
    <Layout>
      <SEO title="Schedule a Demo" />
      <div className="home-container demo">
        <div className="hero first-hero">
          <h1 className="big-h">Schedule a Demo</h1>
          <p className="big-p">Enter your email to schedule a demo</p>

          <div className="access">
            <div style={{ width: "52%" }}>
              <Input
                className="access-field"
                type="email"
                placeholder="enter your email"
                autoFocus={true}
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
                haserror={emailError.length > 0 ? true : false}
                style={{
                  minWidth: "100%",
                  display: "block",
                }}
              />
              {emailError && (
                <span className="email-error">{emailError}</span>
              )}
            </div>
            <button
              className={`access-button darkens dark-button ${
                isEmailFocused ? "active" : ""
              }`}
              onClick={(e) => {
                setEmailError("")
                if (email.length === 0) {
                  setEmailError("Email is required")
                } else if (
                  !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)
                ) {
                  setEmailError("Email is invalid")
                } else {
                  // Assume all good and show the modal
                  onSubmit(e)
                }
              }}
            > 
              {isButtonLoading ? (
                <>Submitting...</>
              ) : (
                <>
                  Submit{" "}
                  <ChevronRight
                    className="access-chevron"
                    style={{ top: 0 }}
                  />
                </>
              )}
            </button>
          </div>
        </div>

        {
          showCalendly &&
          <CalendlyEmbed account="aide" eventName="30min?primary_color=555562" />
        }

        {
          !showCalendly &&
          <style jsx>{`
            footer {
              position: absolute;
              width: 100%;
              bottom: 0;
            }
          `}</style>
        }
      </div>
    </Layout>
  )
}

export default Mission
